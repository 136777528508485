.App {
  text-align: center;
  margin: auto;
  /* border-radius: 5px; */
  /* border: 0.5px solid #444; */
}

/* @media (max-width: 500px) {
  .App {
    max-width: 500px;
  }
} */

.App-logo {
  height: 30vmin;
  pointer-events: none;
  transform: rotate(-30deg);
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.mainContent {
  padding: 50px 0;
}

/* .services {
  display: flex;
  justify-content: space-evenly;
  color: white;
  font-weight: 300;
  padding: 20px;
}

.services div {
  padding: 0 10px;
  border-radius: 5px;
  background-color: rgba(200, 200, 200, 0.15);
  width: 40%;
}
.services div:hover {
  background-color: rgba(200, 200, 200, 0.3);
}

.services li {
  text-align: left;
} */

.mainContent h2 {
  font-weight: 300;
}

/* .far,
.fas {
  color: rgb(112, 0, 0);
  font-size: 1.2em;
  padding-right: 10px;
} */
.section {
  width: 97%;
  margin: 15px auto;
  border-radius: 10px;
  background-color: rgba(200, 200, 200, 0.55);
  padding: 10px 0px;
}
.sectionNoBackground {
  width: 97%;
  margin: 15px auto;
  border-radius: 10px;
  padding: 10px 0px;
}
.section h2 {
  text-align: left;
  margin-left: 30px;
  /* font-size: 2vh; */
  font-size: 15px;
  color: rgb(66, 66, 66);
  font-weight: 300;
}
.txtBlockSpaced p {
  margin: 1.5em auto;
  /* text-align: justify; */
  width: 90%;
  line-height: 1.5;
  text-align: justify;
}
.section button {
  cursor: pointer;
}
/* .fa-linkedin {
  font-size: 1.2em;

  background-color: rgba(200, 200, 200, 0.75);
  color: rgb(10, 102, 194);
  padding: 2px;
  border-radius: 5px;
  margin-right: 10px;
} */
/* .fa-copyright {
  
  color: rgb(182, 0, 0);
} */
.noDisplay div {
  display: none;
}
