.footerContainer {
  background-color: #1f2229;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: antiquewhite;
  font-size: 0.75rem;
}

.copyright img {
  width: 25px;
  padding: 2px;
  height: auto;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.65);
  margin-right: 5px;
}

.footerContainer a {
  font-size: 0.85em;
}
