.ahProfileImg {
  border-radius: 3px;
  height: auto;
  width: 90px;
  transition: width 1s, height 1s, transform 1s;
  margin-top: 20px;
  margin-right: 10%;
  cursor: none;
  user-select: none;
  /* filter: blur(5px); */
}
.ahProfileImg:hover {
  transform: scale(1.4);
}

.ahProfileControls {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about button {
  border-radius: 5px;
  color: white;
  background-color: rgb(112, 0, 0);
  border: none;
  padding: 5px 10px;
  font-size: 0.9em;
  /* transition: width 1s, height 1s, transform 1s; */
  /* transition: 0.5s ease-out; */
  transition: 0.25s linear;
}
#about button:hover {
  color: rgb(112, 0, 0);
  background-color: whitesmoke;
  border: 1px solid;
  /* transform: scale(1.1); */
}

.aboutAllenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px auto;
  margin-top: 30px;
}

.eagleProfileControls {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  cursor: pointer;
  transition: width 1s, height 1s, transform 1s;
}
.eagleProfileControls:hover {
  transform: scale(1.1);
  color: rgb(112, 0, 0);
}

.eagleProfileControls.show button {
  margin: auto;
}
.eagleProfileControls button {
  margin: 30px auto;
}

.eagleProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.eagleProfileContainer p:last-child {
  text-align: center;
  color: rgb(112, 0, 0);
}

.modalTitle {
  color: rgb(112, 0, 0);
  text-align: center;
}

.bbcaText p:last-child {
  text-align: center;
  color: rgb(112, 0, 0);
}

.eagleProfileImg {
  border-radius: 5px;
  height: auto;
  width: 90%;
  transition: width 1s, height 1s, transform 1s;

  cursor: none;
  user-select: none;
}
.eagleIcon {
  width: 60px;
  margin-left: 20px;
}
.progressItem {
  margin: 20px auto;
  text-align: left;
}
.modalFooter {
  display: flex;
  justify-content: center;
}
