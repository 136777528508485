.servicesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.servCard img {
  width: 350px;
  /* width: 50%; */
  margin: 30px auto;
  text-align: center;
  border-radius: 20px;
}
/* .businessIcon {
  border: 0.5px solid #222;
} */
.techIcon {
  padding: 30px 20px;
  /* background-color: rgba(88, 88, 88, 0.6); */

  /* background-color: rgba(88, 88, 88, 0.6); */
  background-image: radial-gradient(
    rgba(88, 88, 88, 0.5) 45%,
    rgba(88, 88, 88, 0)
  );
  /* border: none; */
}
/* .industryIcon {
  border: 0.5px solid #222;
} */
.servCard img:last-child {
  margin-top: 20px;
}
.servCard {
  padding: 20px;
  border: 0.5px solid #222;
  border-radius: 10px;
  margin: 10px;
  background-color: whitesmoke;
  width: 95%;
}
.servCard:hover {
  /* cursor: pointer; */
  /* transform: scale(1.01); */
  background-color: white;
  border: 1px solid rgb(112, 0, 0);
}

.servCard:hover:il {
  background-color: rgba(88, 88, 88, 0.15);
}
.list-group-item {
  background-color: inherit;
}
.servCard h3 {
  margin-top: 30px;
}
.servCard h4 {
  font-weight: 300;
  color: #222;
  margin-bottom: 20px;
}
.serviceItem {
  color: midnightblue;
  font-size: 1.15em;
}

@media (max-width: 450px) {
  .servCard img {
    width: 60vw;
    /* width: 50%; */
  }
}

.portfolioButton {
  width: fit-content;
  margin: auto;
}
.portfolioButton {
  border-radius: 5px;
  color: white;
  background-color: rgb(112, 0, 0);
  border: none;
  padding: 5px 10px;
  font-size: 0.9em;
  /* transition: width 1s, height 1s, transform 1s; */
  /* transition: 0.5s ease-out; */
  transition: 0.25s linear;
}
.portfolioButton:hover {
  color: rgb(112, 0, 0);
  background-color: whitesmoke;
  border: 1px solid;
  /* transform: scale(1.1); */
}
