.contactCard {
  margin: auto;
  width: 85%;
  border-radius: 10px;
  background-color: whitesmoke;
}
.contactCard:hover {
  border: 1px solid rgb(112, 0, 0);
  background-color: white;
}
.contactCardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .contactCard {width:95%}
  .contactCardBody {font-size:0.5rem}
}
#contacts {min-width: 300px;}
.contactsMainImage {
  width: 250px;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: width 1s, height 1s, transform 1s;
}

.contactsMainImage:hover {
  transform: scale(1.1);
}

.contactDetail,
.contactDetail a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: "500";
}

.icon8 {
  padding-right: 20px;
  width: 45px;
  height: auto;
}

.contactsCardContainer a {
  text-decoration: none;
}
.contactDetail.highlight:hover {
  transform: scale(1.1);
}

.deEmphasise {
  color: #aaa;
  font-size: 0.7em;
}
