.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  right: 25px;
  width: 30px;
  height: 30px;
  padding: 5px 40px 5px 10px;
  border: 0.5px solid rgb(112, 0, 0);
  border-radius: 5px;
  background-color: rgb(245, 245, 245);
}
.iconContainer:hover div {
  background-color: rgb(156, 0, 0);
}
.iconElement {
  border-radius: 5px;
  background-color: #333;
  height: 2px;
}
