.sideDrawerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: fixed;
  right: 1px;
  width: 120px;
  height: 40px;
  cursor: pointer;
  background-color: whitesmoke;
  z-index: 10;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  width: 120px;
  top: 59px;
  right: -1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.show .menuContainer {
  border-radius: 0 0 0 5px;
  background-color: rgba(200, 200, 200, 0.85);
  border: 0.5px solid #222;
  /* border-top: none; */
}

.menuContainer a {
  color: #222;
  text-decoration: none;
  font-weight: 300;
  padding: 10px;
  font-size: 16px;
}

.menuContainer a:hover {
  color: rgb(112, 0, 0);
}

.sideDrawerContainer.show {
  border: 0.5px solid #222;
  border-bottom: none;
  border-radius: 5px 0 0 0;
  height: 60px;

  /* transform: translateX(0); */
}

.sideDrawerContainer.hide.original {
  /* transform: translateX(-100%); */
  /* display: none; */

  border-radius: 5px 0px 0px 5px;
  background-color: rgba(245, 245, 245, 0.4);
  width: 78px;
  height: 35px;
}
.sideDrawerContainer.hide:hover {
  background-color: whitesmoke;
}
.sideDrawerContainer.hide.minimised {
  width: 30px;
  height: 30px;
}
.navTop {
  display: flex;
  align-content: center;
  justify-content: center;
  position: fixed;
  right: 0;
  bottom: 7%;
  width: 30px;
  height: 30px;
  padding: 20px;
  color: #333;
  border-radius: 5px 0px 0px 5px;
  background-color: rgba(245, 245, 245, 0.2);
  z-index: 10;
  border: 0.5px solid rgb(46, 46, 46);
}

.navTop a {
  text-decoration: none;
  font-size: 0.65em;
  text-align: center;
  align-self: center;
}

.navTop:hover {
  background-color: whitesmoke;
}
.navTop img {
  margin-top: 5px;
  width: 20px;
  height: 20px;
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: rgba(59, 59, 59, 0.4);
}
