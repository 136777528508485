.education {
  margin-top: 20px;
}

.educationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* @media (max-width: 500px) {
  .educationContainer {
    max-width: 490px;
  }
} */

.qualCard img {
  border-radius: 5px;
  height: 100px;
  margin: 20px auto;
}

.qualCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  height: 400px;
  background-color: rgba(226, 226, 226, 0.5);
  border-radius: 10px;
}

.qualCard:hover {
  background-color: white;
}

@media screen and (max-width:500px) {
  .qualCard {height: auto;}
}

#education p {
  text-align: center;
}
