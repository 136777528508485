.wrapper {
  background-color: #1f2229;
}
.appHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: whitesmoke;
  font-size: calc(10px + 1.5vmin);
  border-radius: 5px 5px 0 0;
}

.headerLogo {
  margin-left: 10px;
  height: 100px;
  width: 250px;
  background-color: whitesmoke;
  padding: 10px;
}
